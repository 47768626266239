import React from 'react';
import {Button} from "../Button";
import {LangLink} from "../LangLink";
import {useIntl} from "gatsby-plugin-react-intl";
import {graphql} from "gatsby";

const CONTACT_PAGE = 'contact-page';
const CONSULTATION_PAGE = 'consultation';
const CURRANT_PAGE_CONTACT_FORM = 'current-page-contact-form';

export const fragment = graphql`
    fragment CTAButton on STRAPI__COMPONENT_UTILS_CTA_BUTTON {
        label
        type
        url
    }
`


export function CTAButtonNew({type, label, url, __typename, ...otherProps}) {
    const intl = useIntl();
    if ((type === 'none' || type === undefined || type === null) && !url) {
        return null;
    }

    const isExternalLink = !!url;

    return <Button component={isExternalLink ? undefined : LangLink}
                   to={url ?? getCtaButtonLink()}
                   target={isExternalLink ? '_blank' : undefined}
                   {...otherProps}>{label || getContent(intl, type)}</Button>;
}

export function getCtaButtonLink() {
    return '/consultation';
}

function getContent(intl, type) {
    if (type === CONTACT_PAGE) {
        return intl.formatMessage({id: 'button.contactCTA'});
    } else if (type === CONSULTATION_PAGE) {
        return intl.formatMessage({id: 'button.consultation'});
    } else if (type === CURRANT_PAGE_CONTACT_FORM) {
        return intl.formatMessage({id: 'button.currentPageContactForm'});
    }
    throw new Error(`Invalid CTA Button type: ${type}`);
}
