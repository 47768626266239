import React, {useMemo} from 'react';
import {LangLink} from "../LangLink";
import {sendAnalyticsEvent} from "../../functions/sendAnalyticsEvent";

import {SidebarBanner} from "../SidebarBanner";


export function ServiceBanner({slug, ...props}) {
    const CTAButtonComponent = useMemo(() => {
        return ({to, ...props}) => {
            return <LangLink to={`/service/${slug}`} {...props} onMouseDown={() => {
                sendAnalyticsEvent('service-banner.click', 'service-banner', slug);
            }}>Learn more</LangLink>
        }
    }, [slug]);

    return <SidebarBanner CtaButtonComponent={CTAButtonComponent} {...props} />
}
