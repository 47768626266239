import React from 'react';
import classNames from "classnames";
import {SVGSupportImg} from "../SVGSupportImg";

import * as styles from './SidebarBanner.module.scss';

export function SidebarBanner({
                                  className,
                                  slug,
                                  title,
                                  withDecorations = true,
                                  CtaButtonComponent,
                                  ctaLink,
                                  text,
                                  image,
                                  ...props
                              }) {
    return <article
        className={classNames(className, styles.root, withDecorations && styles.withDecorations)} {...props}>
        {image ? <div className={styles.image}>
            <SVGSupportImg {...image} />
        </div> : undefined}
        <div className={styles.content}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.text}>{text}</p>
            <CtaButtonComponent className={styles.cta} to={ctaLink}/>
        </div>
    </article>
}
